const ThickX = ({ width }) => {
    return (
        <div className={`thick-x ${width}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.35 82.35">
                <g fill="currentColor">
                    <path d="M71.45 0L40.6 29.45 11.2 0 0 11.2l29.15 29.15L0 68.15 10.9 79.6l29.45-28.05 30.85 30.8L82.35 71.2 51.8 40.6l30.55-29.15L71.45 0z"></path>
                </g>
            </svg>
        </div>
    )
}

export default ThickX
