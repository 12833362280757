import React, { useState, useEffect, Fragment } from 'react'

import { useStore } from '@nanostores/react'
import { isCartOpen, cartData } from '@stores/cartStore.js'
import { createCart, addToCart } from '@api/shopify.js'
import va from '@vercel/analytics'
import { handleRemoveItem } from '@components/Cart/cartUtility.jsx'

import { slugify, getUniqueListBy } from '@utils/utils.js'
import { all } from 'axios'

const FILES_URL = 'https://cdn.shopify.com/s/files/1/2338/2917/files/'

const YourBabyTile = ({
    variants,
    productTitle,
    setFreeYourBabyProductAdded,
}) => {
    const $cartData = useStore(cartData) || null
    const [currentSize, setCurrentSize] = useState({})

    let productsWithVariants
    let productSizes = []
    let productSwatches = []
    let allProductImages = []

    variants = variants.flat(1)

    //

    //
    productSizes = variants.map((v) => {
        if (
            v.inventory_quantity > 0 &&
            v.initial_color === variants[0].initial_color
        ) {
            return {
                variantId: v.id,
                title: v.title,
                price: v.price,
                size: v.initial_size || '',
                initial_size: v.initial_size || '',
                sku: v.sku,
                inventory_quantity: v.inventory_quantity,
            }
        }
    })

    productSizes = productSizes.filter(Boolean)

    //

    // productSizes = getUniqueListBy(productSizes, 'initial_size')

    variants.map((v) => {
        if (v.inventory_quantity > 0 && v.initial_color) {
            productSwatches.push(v.initial_color)
        }
        return null
    })

    productSwatches = [...new Set(productSwatches)]

    allProductImages = variants.map((v) => {
        return {
            src: v.image,
            alt: slugify(v.initial_color),
        }
    })

    //

    const [currentColor, setCurrentColor] = useState(variants[0]?.initial_color)
    const [currentImage, setCurrentImage] = useState({
        src: variants[0]?.image,
        alt: variants[0]?.title,
    })
    const [currentVariantId, setCurrentVariantId] = useState(variants[0]?.id)
    const [currentSizes, setCurrentSizes] = useState(productSizes || [])

    const handleSizeClick = (size, variantId, index, sku) => {
        let ele = document.getElementsByClassName('size size-selected')
        if (ele.length > 0) {
            ele[0].classList.remove('size-selected')
        }

        //
        setCurrentSize({
            size: size,
            variantId: variantId,
            sku: sku,
        })

        setCurrentVariantId(variantId)
    }

    const handleSwatchClick = (swatchSlug, swatchTitle, index) => {
        let ele = document.getElementsByClassName('size size-selected')
        if (ele.length > 0) {
            ele[0].classList.remove('size-selected')
        }
        //

        swatchTitle = swatchTitle.replace(/\//g, ' ')

        //

        let newImage = allProductImages.filter((n, i) => {
            return n.alt === swatchSlug
        })

        //

        productSizes = []

        //
        productSizes = variants.map((v) => {
            if (v.inventory_quantity > 0 && v.initial_color === swatchTitle) {
                return {
                    variantId: v.id,
                    title: v.title,
                    price: v.price,
                    size: v.initial_size || '',
                    initial_size: v.initial_size || '',
                    sku: v.sku,
                    inventory_quantity: v.inventory_quantity,
                }
            }
        })

        productSizes = productSizes.filter(Boolean)

        setCurrentSizes(productSizes)

        //

        setCurrentImage(newImage[0])

        setCurrentColor(swatchTitle)

        setCurrentSize({})
    }

    const handleAddToCart = async (e) => {
        //

        const noClick = e.target.classList.contains('noclick')

        if (noClick) {
            return
        }
        e.preventDefault()

        let cartId = $cartData.cartId || ''

        // console.log cartData in addToCart', $cartData)

        //

        let variantId = currentSize.variantId
        //
        // // console.log variantId, quantity', variantId, quantity)

        const product = `gid://shopify/ProductVariant/${variantId}`

        let lines = {
            quantity: 1,
            merchandiseId: product,
        }

        let quantity = 1

        if (!cartId || cartId === '') {
            let cartCreateData = await createCart()
            cartId = cartCreateData.data.cartId

            // console.log cartCreateData', cartCreateData)

            let cartObj = {
                ...cartData.get(),
                cartId: cartId,
                checkoutUrl: cartCreateData.data.checkoutUrl,
            }

            cartData.set(cartObj)

            addToCart(cartId, lines).then((addToCartData) => {
                // console.log addToCartData', addToCartData)
                let cartLines = addToCartData.body.cartLinesAdd.cart.lines.edges
                let cartCost = addToCartData.body.cartLinesAdd.cart.cost
                // console.log cartLines', cartLines)

                let cartObj = {
                    ...cartData.get(),
                    lines: cartLines,
                    cost: cartCost,
                }

                cartData.set(cartObj)

                isCartOpen.set('is_cart_open', true)

                dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
                dataLayer.push({
                    event: 'add_to_cart',
                    page: 'cart free',
                    ecommerce: {
                        currency: 'USD',
                        value: currentSize.price,
                        items: [
                            {
                                item_id: variantId,
                                item_name: product.title,
                                affiliation: 'Vercel',
                                item_brand: 'Monica + Andy',
                                // item_category: currentPattern ? currentPattern : '',
                                item_category2: currentSize?.size,
                                item_variant: currentSize?.variantId,
                                price: currentSize.price,
                                quantity: quantity,
                            },
                        ],
                    },
                })

                va.track('Free Product Added To Cart', {
                    productName: product.title,
                    productSku: currentSize?.sku,
                    productBrand: product?.vendor
                        ? product.vendor
                        : 'Monica + Andy',
                    // productColor: currentPattern ? currentPattern : '',
                    productSize: currentSize?.size,
                    productVariant: currentSize?.variantId,
                    productPrice: currentSize?.price,
                    productQuantity: quantity,
                })

                setFreeYourBabyProductAdded(true)
            })
        } else {
            // console.log cartId exists', cartId, cartData)

            addToCart(cartId, lines).then((addToCartData) => {
                // console.log data', addToCartData)

                let cartLines = addToCartData.body.cartLinesAdd.cart.lines.edges
                let cartCost = addToCartData.body.cartLinesAdd.cart.cost

                let cartObj = {
                    ...cartData.get(),
                    lines: cartLines,
                    cost: cartCost,
                }

                cartData.set(cartObj)

                isCartOpen.set('is_cart_open', true)

                dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
                dataLayer.push({
                    event: 'add_to_cart',
                    page: 'cart free',
                    ecommerce: {
                        currency: 'USD',
                        value: currentSize?.price,
                        items: [
                            {
                                item_id: variantId,
                                item_name: product.title,
                                affiliation: 'Vercel',
                                item_brand: 'Monica + Andy',
                                // item_category: currentPattern
                                //     ? currentPattern
                                //     : '',
                                item_category2: currentSize?.size,
                                item_variant: currentSize?.variantId,
                                price: currentSize?.price,
                                quantity: quantity,
                            },
                        ],
                    },
                })

                va.track('Free Product Added To Cart')

                setFreeYourBabyProductAdded(true)
            })
        }
    }

    return (
        <Fragment>
            {variants ? (
                <div className="product-outer pt-4 w-full">
                    <div className="product-inner">
                        <div className="product-wrap flex text-left justify-center gap-3">
                            <div className="product-image w-1/2">
                                <img
                                    src={currentImage?.src}
                                    alt={currentImage?.alt}
                                />
                            </div>
                            <div className="product-info w-1/2">
                                <div className="product-title text-sm font-medium mb-2">
                                    {productTitle}
                                </div>
                                <div className="product-price text-sm font-normal">
                                    {/* <span className="line-through text-red-500 font-semibold">
                                        $65.00
                                    </span>{' '} */}
                                    <span className="font-semibold">FREE!</span>
                                </div>
                                <div className="product-actions flex flex-col">
                                    <div className="product-swatches my-2">
                                        <ul className="swatch-list pattern-list nostyle flex flex-wrap gap-x-1">
                                            {productSwatches.map((s, i) => {
                                                //
                                                const swatchSlug = slugify(s)
                                                return (
                                                    <li
                                                        key={swatchSlug}
                                                        className={`swatch swatch-item w-8 hover:cursor-pointer ${
                                                            currentColor &&
                                                            currentColor ===
                                                                s.replace(
                                                                    /\//g,
                                                                    ' '
                                                                )
                                                                ? 'swatch-selected'
                                                                : ''
                                                        }`}
                                                        data-swatch-index={i}
                                                        data-swatch-color={
                                                            swatchSlug
                                                        }
                                                        aria-label={s}
                                                        data-balloon-pos="up"
                                                        onClick={() =>
                                                            handleSwatchClick(
                                                                swatchSlug,
                                                                s,
                                                                i
                                                            )
                                                        }
                                                    >
                                                        <div className="swatch-image">
                                                            <img
                                                                className="rounded-full border border-oat hover:border-ocean hover:border-2"
                                                                src={`${FILES_URL}maswatch-${swatchSlug}-96.png`}
                                                                alt={s.color}
                                                            />
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div className="product-sizes my-2">
                                        {currentSizes &&
                                        currentSizes.length > 0 ? (
                                            <ul className="size-list nostyle flex flex-wrap gap-1 lg:gap-2">
                                                {currentSizes.map((s, i) => {
                                                    //
                                                    return (
                                                        <li
                                                            key={s.initial_size}
                                                            className={`size h-[18px] lg:h-[24px] border border-oat rounded-sm flex items-center justify-center px-2 text-xs hover:cursor-pointer hover:bg-flint hover:text-white w-[48px] ${
                                                                currentSize.size &&
                                                                currentSize.size ===
                                                                    s.initial_size
                                                                    ? 'size-selected bg-ash text-white'
                                                                    : 'bg-white'
                                                            } ${
                                                                s.inventory_quantity ===
                                                                0
                                                                    ? 'size-out-of-stock cursor-not-allowed pointer-events-none'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                handleSizeClick(
                                                                    s.initial_size,
                                                                    s.variantId,
                                                                    i,
                                                                    s.sku
                                                                )
                                                            }
                                                            data-variant-id={
                                                                s.variantId
                                                            }
                                                        >
                                                            <span className="size-label text-xs">
                                                                {s.initial_size}
                                                            </span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        ) : null}
                                    </div>

                                    <div className="product-add-to-cart w-full flex mt-3">
                                        {productSizes && productSizes.length ? (
                                            <button
                                                type={
                                                    !currentSize.size
                                                        ? null
                                                        : 'submit'
                                                }
                                                className={`large-button button-atc h-42px w-full ${
                                                    !currentSize.size
                                                        ? 'disabled noclick cursor-not-allowed opacity-20'
                                                        : ''
                                                }`}
                                                data-variant-id={
                                                    currentSize.variantId
                                                }
                                                // data-balloon-blunt
                                                data-balloon-pos="up"
                                                aria-label={
                                                    !currentSize.size
                                                        ? 'Please select a size'
                                                        : null
                                                }
                                                onClick={(e) =>
                                                    handleAddToCart(e)
                                                }
                                            >
                                                Add To Cart
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </Fragment>
    )
}
export default YourBabyTile
