import React, { useState, useEffect, Fragment } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useStore } from '@nanostores/react'
import { isCartOpen, cartData } from '@stores/cartStore.js'
import va from '@vercel/analytics'
import 'balloon-css'
import { createCart, addToCart } from '@api/shopify.js'
import { slugify, getUniqueListBy } from '@utils/utils.js'
import { handleRemoveItem } from '@components/Cart/cartUtility.jsx'
import { c, p } from '../../data/navdata'

import YourBabyTile from '@components/Cart/YourBabyTile.jsx'
import AngleLeft from '@components/Icons/AngleLeft.jsx'
import AngleRight from '@components/Icons/AngleRight.jsx'

const FREE_PRODUCT_QUERY_URL =
    'https://services.mybcapps.com/bc-sf-filter/search?shop=monicaandandyonline.myshopify.com&limit=70&collection_scope=269814726718'

const FILES_URL = 'https://cdn.shopify.com/s/files/1/2338/2917/files/'

const productData = await fetch(FREE_PRODUCT_QUERY_URL).then((r) => r.json())

//

let productArray = productData.products
let laborGownProducts = []
let pajamaSetProducts = []
let laborGownVariants = []
let pajamaSetVariants = []
let allVariants = productArray.map((p) => {
    return p.variants
})

allVariants = allVariants.flat(1)

productArray.map((p) => {
    if (p.title.indexOf('Labor Gown') > -1) {
        laborGownProducts.push(p)
    } else if (p.title.indexOf('Pajama Set') > -1) {
        pajamaSetProducts.push(p)
    }
    return null
})

laborGownProducts?.map((p) => {
    laborGownVariants.push(p.variants)
    return null
})

pajamaSetProducts?.map((p) => {
    pajamaSetVariants.push(p.variants)
    return null
})

laborGownVariants = laborGownVariants.flat(1)
pajamaSetVariants = pajamaSetVariants.flat(1)

//

//
//

const freeProductIds = [
    7292248358974, 7292246163518, 7292247900222, 7292253831230, 7292254158910,
    40314514636862, 40314514669630, 40314514702398, 40314503692350,
    40314503725118, 40314503757886, 40314503790654, 40314503823422,
    40314503856190, 40314477838398, 40314477871166, 40314477903934,
    40314477936702, 40314477969470, 40314478002238, 40314502774846,
    40314502807614, 40314502840382, 40314502873150, 40314502905918,
    40314502938686, 40314515062846, 40314515095614, 40314515128382,
]

const YourBabyOffer = () => {
    const $cartData = useStore(cartData) || null
    const [yourBabyThresholdMet, setYourBabyThresholdMet] = useState(false)
    const [currentSize, setCurrentSize] = useState({})
    const [freeYourBabyProductAdded, setFreeYourBabyProductAdded] =
        useState(false)
    const [activeDot, setActiveDot] = useState(0)
    const [yourBabyThreshold, setYourBabyThreshold] = useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [yourBabyData, setYourBabyData] = useState({})

    let freeProduct

    useEffect(() => {
        if (yourBabyData && yourBabyData.freeProduct) {
            setYourBabyThreshold(
                parseFloat(yourBabyData.freeProduct).toFixed(2)
            )
        }
    }, [yourBabyData])

    useEffect(() => {
        let cartLines

        if ($cartData) {
            cartLines = $cartData.lines

            let freeProductInCart = cartLines?.filter((line) => {
                //
                const lineId = Number(line.node.merchandise.id.split('/').pop())
                return freeProductIds.includes(lineId)
            })

            if (freeProductInCart && freeProductInCart.length > 0) {
                setFreeYourBabyProductAdded(true)
            } else {
                setFreeYourBabyProductAdded(false)
            }
        }
    }, [$cartData])

    useEffect(() => {
        //
        if (yourBabyThreshold && yourBabyThreshold <= 0) {
            setYourBabyThresholdMet(true)

            return
        } else {
            setYourBabyThresholdMet(false)

            let cartLines
            let itemToBeRemoved

            if ($cartData) {
                cartLines = $cartData.lines

                itemToBeRemoved = cartLines?.filter((line) => {
                    const item = line.node
                    let id = item?.merchandise?.id.split('/').pop()
                    id = Number(id)
                    //
                    return freeProductIds.includes(id)
                })

                //

                if (
                    itemToBeRemoved &&
                    itemToBeRemoved.length &&
                    freeYourBabyProductAdded
                ) {
                    const cartId = $cartData.cartId
                    const itemToBeRemovedId = itemToBeRemoved[0]?.node.id

                    handleRemoveItem(cartId, itemToBeRemovedId)
                    setFreeYourBabyProductAdded(false)

                    let $newCartData = cartData.get()

                    setYourBabyData({
                        ...$newCartData,
                        freeProduct:
                            parseFloat(75 - $newCartData.subTotal).toFixed(2) ||
                            0,
                    })
                }
            }
        }
    }, [yourBabyThreshold])

    useEffect(() => {
        if ($cartData) {
            let $newCartData = cartData.get()

            const invalidProductTypes = [
                'Gift Cards',
                'Gift Card',
                'Specialty SKU',
                'Embroidery Option',
                'Embroidery',
                'Virtual Classes',
                'Classes + Events',
                'Nursery',
                'Cribs',
                'Classes',
                'Strollers',
            ]

            const lines = $newCartData?.lines || null

            let applicableLines

            // get applicable items subtotal
            if (lines && lines.length > 0) {
                applicableLines = lines.filter((line) => {
                    const item = line.node
                    const productType = item?.merchandise?.product?.productType
                    const vendor = item?.merchandise?.product?.vendor
                    return (
                        !invalidProductTypes.includes(productType) &&
                        vendor === 'Monica + Andy'
                    )
                })
            }

            //

            let subTotal
            let subtotal

            if (applicableLines && applicableLines.length > 0) {
                subtotal = applicableLines.reduce(function (
                    previousValue,
                    currentValue
                ) {
                    //
                    return (
                        parseFloat(previousValue) +
                        parseFloat(currentValue.node.cost.totalAmount.amount)
                    )
                },
                0)
            } else {
                subtotal = 0
                freeProduct = 75
            }

            if (subtotal) {
                subTotal = parseFloat(subtotal).toFixed(2) || 0
                freeProduct = parseFloat(75 - subTotal).toFixed(2) || 0
            } else {
                subTotal = 0
                freeProduct = 75
            }

            setYourBabyData({
                ...$newCartData,
                subTotal: subTotal,
                freeProduct: freeProduct ? freeProduct : 0,
            })
        }
    }, [$cartData])

    //

    const handlePrev = (e) => {
        let ele = document.getElementsByClassName('free-products-list')[0]
        let elementWidth = ele.offsetWidth
        //
        ele.scrollLeft -= elementWidth
        if (activeDot === 1) {
            setActiveDot(0)
        }
    }

    const handleNext = (e) => {
        let ele = document.getElementsByClassName('free-products-list')[0]
        let elementWidth = ele.offsetWidth
        //
        ele.scrollLeft += elementWidth
        if (activeDot === 0) {
            setActiveDot(1)
        }
    }

    //

    return (
        <Fragment>
            {yourBabyThresholdMet && !freeYourBabyProductAdded ? (
                <div className="free-product-outer mb-6 bg-sun py-4">
                    <div className="free-product-inner flex flex-col text-center justify-center">
                        <div className="free-product-header mb-3">
                            <div className="free-product-title text-sm font-medium px-4">
                                <h3 className="text-lg lg:text-xl mb-2">
                                    Select one of these FREE gifts!
                                </h3>
                            </div>
                            <p className="text-xs font-normal">
                                Please select your options and add the item to
                                your cart.
                            </p>
                        </div>
                        <div className="free-products-wrap relative">
                            <div
                                className={`free-product-nav nav-prev absolute top-1/2 -translate-y-1/2 left-1 ${
                                    activeDot === 0
                                        ? 'cursor-not-allowed opacity-50'
                                        : 'cursor-pointer'
                                }`}
                                onClick={handlePrev}
                            >
                                <AngleLeft width="w-[14px]" />
                            </div>
                            <ul className="free-products-list flex flex-row overflow-hidden bg-white px-4">
                                <li className="free-product-item flex-none w-full px-4">
                                    <YourBabyTile
                                        variants={laborGownVariants}
                                        productTitle="Organic Maternity Labor Gown"
                                        setFreeYourBabyProductAdded={
                                            setFreeYourBabyProductAdded
                                        }
                                    />
                                </li>
                                <li className="free-product-item flex-none w-full px-4">
                                    <YourBabyTile
                                        variants={pajamaSetVariants}
                                        productTitle="Organic Maternity Pajama Set"
                                        setFreeYourBabyProductAdded={
                                            setFreeYourBabyProductAdded
                                        }
                                    />
                                </li>
                            </ul>
                            <div
                                className={`free-product-nav nav-next absolute top-1/2 -translate-y-1/2 right-1 ${
                                    activeDot === 1
                                        ? 'cursor-not-allowed opacity-50'
                                        : 'cursor-pointer'
                                }`}
                                onClick={handleNext}
                            >
                                <AngleRight width="w-[14px]" />
                            </div>

                            <div className="dots-nav">
                                <div className="dots-inner flex flex-row justify-center gap-3 mt-4 mb-2">
                                    <div
                                        className={`dot rounded-full w-3 h-3 border border-ash cursor-pointer ${
                                            activeDot === 0
                                                ? 'bg-ash'
                                                : 'bg-white '
                                        }`}
                                        onClick={handlePrev}
                                    ></div>
                                    <div
                                        className={`dot rounded-full w-3 h-3 border border-ash cursor-pointer ${
                                            activeDot === 1
                                                ? 'bg-ash'
                                                : 'bg-white'
                                        }`}
                                        onClick={handleNext}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Fragment>
                    {!freeYourBabyProductAdded ? (
                        <Fragment>
                            <div className="free-product-remainder-outer bg-sun">
                                <div className="free-product-remainder-inner p-4 flex flex-col items-center justify-center">
                                    <div className="free-product-remainder flex items-center text-center justify-center">
                                        {yourBabyData &&
                                        yourBabyData.subTotal > 0 &&
                                        yourBabyData.subTotal < 75 &&
                                        yourBabyData.freeProduct ? (
                                            <span className="text-sm">
                                                Spend{' '}
                                                <span className="font-medium">
                                                    ${yourBabyData.freeProduct}
                                                </span>{' '}
                                                more to get a FREE Gift!
                                            </span>
                                        ) : yourBabyData.subTotal >= 75 ? (
                                            <span className="text-sm">
                                                You've qualified for a FREE
                                                Gift!
                                            </span>
                                        ) : (
                                            <span className="text-sm">
                                                Spend $75 and get a FREE Gift!
                                            </span>
                                        )}
                                    </div>
                                    <div className="free-product-images mt-2">
                                        <ul className="product-list flex gap-3 items-center justify-center px-4">
                                            <li>
                                                <img
                                                    src="https://cdn.shopify.com/s/files/1/2338/2917/files/Monica_Andy-0323-1157_e74be569-12cc-443a-bb22-95a5632ecaa5.jpg?v=1701902152"
                                                    alt="Free Organic Maternity Gown"
                                                />
                                                <p className="text-sm text-center leading-1">
                                                    FREE Organic Maternity Gown
                                                </p>
                                            </li>
                                            <li>
                                                <img
                                                    src="https://cdn.shopify.com/s/files/1/2338/2917/files/Image4_c83afc1a-28c3-4e57-820d-42e2e7b2f1b5.png?v=1701900252"
                                                    alt="Free Organic Maternity Pajama Set"
                                                />
                                                <p className="text-sm text-center leading-1">
                                                    FREE Organic Maternity
                                                    Pajama Set
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="free-shipping-details text-xs text-center font-normal">
                                <span
                                    className="cursor-pointer"
                                    onClick={() => setShowDetails(!showDetails)}
                                >
                                    * {showDetails ? 'Hide ' : 'Show '}Details
                                </span>
                                {showDetails ? (
                                    <div className="text-[10px] text-center font-normal px-4">
                                        Based on elligible products in cart.
                                        Gift Cards, embroidery, classes, digital
                                        products, and specialty SKUs do not
                                        count towards the $75 threshold.
                                    </div>
                                ) : null}
                            </div>
                        </Fragment>
                    ) : null}
                </Fragment>
            )}
        </Fragment>
    )
}
export default YourBabyOffer
